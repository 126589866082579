<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-between mb-4">
          <b-button
            v-if="hasDeletedUser"
            class="mb-4"
            variant="warning"
            @click="prepareDeletedLink()"
            >Silinmiş Kullanıcılar</b-button
          >
          <!-- <b-button
            v-if="showButtons"
            class="mb-4"
            variant="primary"
            @click="prepareLink()"
            >Yeni Kullanıcı</b-button
          > -->
        </div>
        <table class="table datatable" ref="datatable">
          <thead>
            <tr>
              <th>{{ $t("FORM.ID") }}</th>
              <th>{{ $t("FORM.FULL_NAME") }}</th>
              <th>{{ $t("FORM.EMAIL") }}</th>
              <th>{{ $t("FORM.PHONE") }}</th>
              <th>{{ $t("LIST.CREATED_AT") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in users" :key="user.id">
              <td>{{ user.id }}</td>
              <td>{{ user.firstName }} {{ user.lastName }}</td>
              <td>{{ user.email }}</td>
              <td>{{ user.phone }}</td>
              <td>
                {{ user.createdAt | moment("DD.MM.YYYY, HH:mm") }}
              </td>
              <td class="qr px-0 text-center">
                <!-- <button
                  v-if="showButtons"
                  class="edit-button"
                  @click="prepareLink(user)"
                >
                  <b-icon-pencil variant="primary"></b-icon-pencil>
                </button> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/store/modules/breadcrumbs";
import { mapActions } from "vuex";

export default {
  components: {},
  props: ["companyId"],
  data() {
    return {
      users: {},
      hasDeletedUser: 0,
      showButtons: undefined
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("ROUTES.CORPORATE") },
      { title: this.$t("ROUTES.USERS") }
    ]);
  },
  watch: {
    companyId: function() {
      this.fetchUsers();
    }
  },
  methods: {
    ...mapActions({
      getUsers: "company/GET_USERS",
      deleteSelectedUser: "user/DELETE_USER",
      setSelectedUser: "user/SET_SELECTED_USER",
      clearSelectedUser: "user/CLEAR_SELECTED_USER"
    }),
    async fetchUsers() {
      this.showButtons = !this.companyId;

      const users = await await this.getUsers(this.companyId);
      this.users = await users.users;
      this.hasDeletedUser = users.deletedUsersCount;
    },
    prepareLink(user) {
      const action = user ? this.setSelectedUser : this.clearSelectedUser;

      action(user).then(() => {
        this.$router.push({ name: "company-companies-company-users" });
      });
    },
    prepareDeletedLink() {
      this.$router.push({ name: "company-companies-company-users-deleted" });
    }
  },
  async created() {
    this.fetchUsers();
  }
};
</script>

<style lang="scss" scoped>
.edit-button {
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
}
</style>
